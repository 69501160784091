import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, 자사 간편 통신앱 ‘핀다이렉트’ 출시",
  "| AI기반 내 폰 진단 및 시세조회 기능 탑재",
  "| 전 세계 143개국 대상 해외데이터로밍 기능 탑재",
  "| 내 요금 관리 기능 통해 통신자산 관리 기능 강화",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/32/32_img1.png" style={{ maxWidth: "300px", margin: "auto" }} alt="Figure2" />
      </div>
    </div>
    <p>
      <br />
      스테이지파이브(대표 서상원)는 요금제 가입과 관리 뿐 아니라 내 폰 시세조회, 해외데이터로밍까지 가능한 올인원 통신앱
      ‘핀다이렉트’를 출시했다고 17일 밝혔다. 해당 앱은 안드로이드와 IOS 모두 지원이 가능하며, 각 운영체제 별 앱스토어에서 다운로드
      가능하다.
      <br />
      <br />
      핀다이렉트 앱은 주요 기능으로 ▲내 폰 진단 및 시세조회 ▲해외데이터로밍 가입 및 내 로밍 현황 조회 ▲요금제 가입 및 내 요금제
      현황 조회 등을 탑재하고 있다.
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/32/32_img2.png" alt="Figure2" />
        </div>
        <div className="desc">&lt;핀다이렉트앱에서 제공하는 ‘내 폰 시세조회 서비스’&gt;</div>
      </div>
      <br />
      <br />
      ‘내 폰 시세조회’ 기능의 경우 외부 전문가의 감정이나 별도의 장비 없이도 간단한 절차만을 통해 내 폰의 정확한 매입가를 직접
      알아볼 수 있도록 했다. 구체적으로, AI 기능 진단 서비스를 통해 8가지 주요 기능을 셀프 검사하면 기기 상태에 따른 정확한 등급과
      가격을 진단 결과로 확인할 수 있다. 결과도 즉각적으로 보여진다. 절차에 따라 장시간 기다려야 했던 상태 점검 서비스와
      차별화되는 부분이다. 또한, 앱을 설치한 스마트폰 정보를 자동인식해서 해당 기기의 시세 흐름을 월 단위로 파악할 수 있도록
      하였다.
      <br />
      <br />
      ‘해외데이터로밍’ 기능의 경우 USIM상품과 더불어 배송과정 없이 누구나 손쉽게 가입 및 연장이 가능하도록 eSIM기능을 강화한 것이
      특징이다. 전 세계 143개국 대상으로 제공, 최근 급증하고 있는 해외여행객 수요 맞춤형으로 접근하였다. 3일 2.5GB 데이터 사용
      기준, 주요 통신사 정가 대비 약 50% 저렴한 금액으로 서비스를 이용할 수 있다.
      <br />
      <br />
      핀다이렉트 앱은 상기 기능들을 활용하여 인앱에서 더욱 직관적이고 쉬운 접근을 할 수 있도록 돕는다. 때문에 고객이 주도적으로
      통신라이프를 관리하는 것이 가능하다. 이를 통해 핀다이렉트의 주 고객층인 MZ세대들에게 초 개인화된 새로운 통신경험 제공할
      예정이다. 핀다이렉트 고객 중 MZ세대인 2-30대 비율은 70% 이상에 육박한다.
      <br />
      <br /> 스테이지파이브 관계자는 "플랫폼 리뉴얼 이전 대비 월 유입 고객이 2,300% 증가하여 급격한 제이커브로 성장세인 만큼, 더욱
      세심한 서비스를 제공하려 한다” 며 "앞으로도 통신 시장에 없었던 새로움을 지속적으로 선보일 수 있도록 하고, 통신을 제공하는
      기업과 소비자 사이의 정보 비대칭을 해소하는데 노력하겠다”고 밝혔다.
      <br />
      <br />
      스테이지파이브는 앱 출시를 맞이하여 3월 말까지 앱 다운로드 고객 대상으로 상품권을 지급하는 이벤트 및 해외데이터로밍 상품을
      정가대비 25% 할인하는 이벤트를 진행할 예정이다.
      <br />
    </p>
  </div>
)

const press27 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2023년 2월 17일 조간 즉시배포 가능"
      pageInfo="(사진자료 메일 첨부)"
      title="내 폰 시세조회, 해외데이터로밍 이용, 내 요금 관리까지 앱 하나로… 올인원 통신앱 ‘핀다이렉트’ 출시"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press27
